export const menuTree = [
  {
    separator: true,
    roles: ["TTG_ADMIN", "TTG_USER", "SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"],
  },
  {
    label: "Ürün İşlemleri",
    icon: "pi pi-fw pi-cog",
    roles: ["TTG_ADMIN", "SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"],
    items: [
      {
        label: "Ürün Ağacı",
        icon: "pi pi-fw pi-search",
        to: "/Model",
        roles: ["TTG_ADMIN", "SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
      },
      {
        label: "Ürün Kod Yönetimi",
        icon: "pi pi-fw pi-search",
        to: "/ProductManagement-Admin",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Ürün Fiyat Yönetimi",
        icon: "pi pi-fw pi-search",
        to: "/ProductPriceAdmin-Disty",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Fiyat Toleransları",
        icon: "pi pi-fw pi-search",
        to: "/Marj",
        roles: ["TTG_ADMIN"]
      },

      {
        label: "Onay Bekleyen Ürünler",
        icon: "pi pi-fw pi-search",
        to: "/WaitingMyAction",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Onay Bekleyen Ürün Fiyatları",
        icon: "pi pi-fw pi-search",
        to: "/WaitingMySupplyPrice",
        roles: ["DISTY_ADMIN"]
      },
      {
        label: "Ürün Kod Yönetimi",
        icon: "pi pi-fw pi-search",
        to: "/ProductManagement",
        roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
      },
      {
        label: "Ürün Kod Excelden Aktarım",
        icon: "pi pi-fw pi-search",
        to: "/ProductExcel",
        roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
      },
      {
        label: "Ürün-Fiyat Yönetimi",
        icon: "pi pi-fw pi-search",
        to: "/ProductPrice-Supply",
        roles: ["SUPPLY_USER", "SUPPLY_ADMIN"]
      },
      {
        label: "Ürün Fiyat Excelden Aktarım",
        icon: "pi pi-fw pi-search",
        to: "/ProductPrice-Supply-Excel",
        roles: ["DISTY_USER", "DISTY_ADMIN", "SUPPLY_USER", "SUPPLY_ADMIN"]
      },
      {
        label: "Ürün - Arıza Bilgi",
        icon: "pi pi-fw pi-search",
        to: "/ProductFault-Update",
        roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_ADMIN"]
      },
      {
        label: "Ürün Fiyat Yönetimi",
        icon: "pi pi-fw pi-search",
        to: "/ProductPrice-Disty",
        roles: ["DISTY_USER", "DISTY_ADMIN"]
      },

      {
        label: "Ürün Fiyat Toplu Güncelleme Excelden Aktarım(Distribütör)",
        icon: "pi pi-fw pi-search",
        to: "/ProductPrice-Disty-Excel",
        roles: ["DISTY_USER", "DISTY_ADMIN"]
      },
      {
        label: "Bayi Sipariş Ana Sayfaya Ürün Ekleme",
        icon: "pi pi-fw pi-search",
        to: "/addToDashboard",
        roles: ["TTG_ADMIN"]
      }


    ]
  },

  {
    label: "Kategori ve Alt Kategori Ekranı",
    icon: "pi pi-fw pi-cog",
    to: "/CategoryView",
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
  },
  {
    label: "Kategori ve Alt Kategori Yönetimi",
    icon: "pi pi-fw pi-cog",
    to: "/CategoryManagement",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Marka Yönetimi",
    icon: "pi pi-fw pi-cog",
    roles: ["TTG_ADMIN"],
    items: [
      {
        label: "Marka Tanımlama",
        icon: "pi pi-fw pi-search",
        to: "/brandCreate",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Marka-Tedarikçi Yönetimi",
        icon: "pi pi-fw pi-search",
        to: "/CanSell",
        roles: ["TTG_ADMIN"]
      },
    ],
  },
  {
    label: "SMS-Mail Tanımlama",
    icon: "pi pi-fw pi-cog",
    to: "/smsMailTemplate",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Kullanıcı Tanımla",
    icon: "pi pi-user-plus",
    to: "/DefineUser",
    roles: ["RESELLER_ADMIN"]
  },
  {
    label: "Dağıtım Bilgileri",
    icon: "pi pi-fw pi-search",
    to: "/saleInfoList",
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    label: "Disty ve Tedarikçi Kullanıcı Tanımlama",
    icon: "pi pi-user-plus",
    to: "/DefineUser",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Bayi Kullanıcı Tanımlama",
    icon: "pi pi-user-plus",
    to: "/DefineResellerUser",
    roles: ["TTG_ADMIN"]
  },

  {
    label: "TTG Kullanıcısı Tanımla",
    icon: "pi pi-user-plus",
    to: "/DefineTTGUser",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Parametreler",
    icon: "pi pi-fw pi-cog",
    to: "/paramList",
    roles: ["TTG_ADMIN"]
  },

  {
    label: "Bannerler",
    icon: "pi pi-fw pi-cog",
    to: "/bannerList",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Alt Kategori Stok Durumları",
    icon: "pi pi-fw pi-cog",
    to: "/stockStatus",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Rapor",
    icon: "pi pi-fw pi-cog",
    roles: ["TTG_ADMIN"],
    items: [
      {
        label: "Son 6 Ay Satış Raporu",
        icon: "pi pi-fw pi-search",
        to: "/reportingMonthly",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Günlük Satış Raporu",
        icon: "pi pi-fw pi-search",
        to: "/dailySaleReport",
        roles: ["TTG_ADMIN"]
      }
    ]
  },
  {
    label: "Kampanya İşlemleri",
    icon: "pi pi-fw pi-cog",
    roles: ["TTG_ADMIN"],
    items: [
      {
        label: "TT CRM",
        icon: "pi pi-fw pi-search",
        to: "/ttCrmList",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Kampanya Tanımlama",
        icon: "pi pi-fw pi-search",
        to: "/accessoryCampaignList",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Seri Numaralar",
        icon: "pi pi-fw pi-search",
        to: "/accessoryCampaignSerialTitleList",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Seri Numaraları Pasife Alma",
        icon: "pi pi-fw pi-search",
        to: "/passiveSerial",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Şifreler",
        icon: "pi pi-fw pi-search",
        to: "/accessoryCampaignPassTitleList",
        roles: ["TTG_ADMIN"]
      },
    ],
  },
  {
    label: "İşlemler",
    icon: "pi pi-fw pi-search",
    to: "/productSaleList",
    roles: ["RESELLER_ADMIN", "RESELLER_USER", "TTG_CUSTOMER_SERVICE"]
  },
  {
    label: "Bayi Seri No Devir",
    icon: "pi pi-fw pi-search",
    to: "/mySerialTransfers",
    roles: ["RESELLER_ADMIN"]
  },
  {
    label: "Benden Devir Alınan Seriler",
    icon: "pi pi-fw pi-search",
    to: "/serialTransfersFromMe",
    roles: ["RESELLER_ADMIN"]
  },
  /* {
     label: "Ürün Fiyatları",
     icon: "pi pi-fw pi-search",
     to: "/productPriceInfoList",
     roles: ["RESELLER_ADMIN"]
   },*/
  /*{
    label: "Bayi Puan İşlemleri",
    icon: "pi pi-fw pi-search",
    to: "/resellerScoreTransactions",
    roles: ["RESELLER_ADMIN"]
  },*/
  {
    label: "Bayi Puanı İle Hediye Kataloğundan Ürün Alma",
    icon: "pi pi-fw pi-search",
    to: "/giftTransaction",
    roles: ["RESELLER_ADMIN"]
  },


  {
    label: "Hediye İşlemleri",
    icon: "pi pi-fw pi-cog",
    roles: ["TTG_ADMIN"],
    items: [
      {
        label: "Hediye Kataloğu Tanımlama",
        icon: "pi pi-fw pi-search",
        to: "/defineResellerScoreOfGift",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Bayi Hediye Taleplerini Onaylama",
        icon: "pi pi-fw pi-search",
        to: "/approveResellerScoreOfGift",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Hediye Kataloğu Açık Olacağı Tarihler",
        icon: "pi pi-fw pi-search",
        to: "/defineResellerScoreOfGiftValidDate",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Kampanya Serisi İle Puan Onaylama",
        icon: "pi pi-fw pi-search",
        to: "/approveCampaignSerials",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Puan Kazanımı Durumu",
        icon: "pi pi-fw pi-search",
        to: "/scoreEarning",
        roles: ["TTG_ADMIN"]
      },
    ]
  },
  {
    label: "Dağıtım",
    icon: "pi pi-fw pi-cog",
    roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN"],
    items: [
      {
        label: "Distribütör Dağıtım Bilgileri",
        icon: "pi pi-fw pi-search",
        to: "/saleInfoList",
        roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN"]
      },
      {
        label: "Tedarikçi Dağıtım Bilgileri",
        icon: "pi pi-fw pi-search",
        to: "/supplyInvoiceList",
        roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN"]
      },
      {
        label: "İşlemler",
        icon: "pi pi-fw pi-search",
        to: "/productSaleList",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Bayi Satış İşlemleri",
        icon: "pi pi-fw pi-search",
        to: "/productSaleList",
        roles: ["SUPPLY_ADMIN", "DISTY_ADMIN"]
      },
      {
        label: "İşlem İptal",
        icon: "pi pi-fw pi-search",
        to: "/cancelSale",
        roles: ["TTG_ADMIN"]
      },
      {
        label: "Distribütör Stok Bilgileri",
        icon: "pi pi-fw pi-search",
        to: "/modelStockList",
        roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN"]
      },
      {
        label: "Bayi Listesi",
        icon: "pi pi-fw pi-search",
        to: "/resellerList",
        roles: ["TTG_ADMIN", "DISTY_ADMIN"]
      },
      {
        label: "Arızalı Ürün Bildirimleri",
        icon: "pi pi-fw pi-search",
        to: "/faultyProductSearch",
        roles: ["TTG_ADMIN"]
      }
    ],
  }, {
    label: "Sipariş Yönetimi",
    icon: "pi pi-fw pi-cog",
    roles: ["RESELLER_ADMIN", "TTG_ADMIN", "DISTY_ADMIN"],
    items: [
      {
        label: "Sipariş Ekranı",
        icon: "pi pi-fw pi-search",
        to: "/resellerOrderManagement",
        roles: ["RESELLER_ADMIN"],

      }, {
        label: "Sipariş Takibi",
        icon: "pi pi-fw pi-search",
        to: "/resellerProductOrder/follow",
        roles: ["RESELLER_ADMIN", "TTG_ADMIN", "DISTY_ADMIN"]

      }
    ]
  },
  process.env.REACT_APP_STAGE === "test" && {
    label: "Bayi Olarak Giriş",
    icon: "pi pi-fw pi-cog",
    to: "/adminResellerLogin",
    roles: ["TTG_ADMIN"]
  },
  {
    label: "Arızalı Ürün Bildirimi",
    icon: "pi pi-fw pi-search",
    to: "/faultyProductNotification",
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    label: "Arızalı Ürün Bildirimlerim",
    icon: "pi pi-fw pi-search",
    to: "/faultyProductList",
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    label: "Arızalı Ürün Değişimi",
    icon: "pi pi-fw pi-search",
    to: "/faultyProductExchange",
    roles: ["SUPPLY_ADMIN", "SUPPLY_USER"]
  },
  {
    label: "Bayi Parametreleri",
    icon: "pi pi-fw pi-cog",
    to: "/resellerParamList",
    roles: ["RESELLER_ADMIN"]
  },

  {
    label: "Ürün Fiyat Listesi",
    icon: "pi pi-fw pi-cog",
    to: "/productPrices",
    roles: ["RESELLER_ADMIN"]
  },

  {
    label: "Kullanıcı Tanımla",
    icon: "pi pi-fw pi-plus",
    to: "/addUserDist",
    roles: ["DISTY_ADMIN"]
  }
]
